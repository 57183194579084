import { Component, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
// Services
import { PrismicService } from '@endurmenntun/_services/prismic/prismic.service';
import { MetaDataService } from '@endurmenntun/_services/meta.service';
import { GeneralService } from '@endurmenntun/_services/general/general.service';
// Models
import {
  Namskeid,
  PortalData,
} from '@endurmenntun/_components/pages/education/interfaces/namskeid.interface';
import { PrismicBaseInterface } from '@endurmenntun/_models/prismic/prismic-base.interface';
import { SingleCourseOrClassQuery } from './../../interfaces/filemaker-query.interface';
import { ReviewEntry } from '@endurmenntun/_components/components/reference-card-component/reference-card.component';
import { FakeMakerService } from '@endurmenntun/fake-maker/fake-maker.service';
import {
  CoursesService,
  CourseTabsService,
  PlannedCoursesService,
  CatalogProductsService,
  TeacherEnrollmentsService,
  TeachersService,
  ProgramProgramsService,
  Course,
  CourseTab,
  CourseTabContent,
  ProductWithIncludes,
  TeacherWithIncludes,
  Program,
  Edition,
  ProgramEditionsService,
  PlannedCourseWithIncludes,
  CatalogVariantsService,
  VariantWithIncludes,
} from 'src/eduframe';
import { environment } from 'src/environments/environment';

enum EduframePageType {
  Course = 'namskeid',
  Program = 'namsbrautir',
}

@Component({
  selector: 'en-namskeid',
  templateUrl: './namskeid.component.html',
  styleUrls: ['./namskeid.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class NamskeidComponent {
  public prismicEducationGrant: any;
  public prismicAlert: any;
  public course: Namskeid;
  public gallery: PortalData;
  public umsagnir: Array<ReviewEntry> = [];
  public umsagnirType: string = 'normal';
  public courseType: any;
  public courseNotFound: boolean = false;
  public loaded: Observable<boolean>;
  public prismicLoaded: Observable<boolean>;
  public fmLoaded: Observable<boolean>;

  // Eduframe
  public eduframePageType: EduframePageType;

  // Eduframe course data
  public eduframeCourse: Course;
  public eduframePlannedCourse: PlannedCourseWithIncludes;
  public eduframeVariant: VariantWithIncludes;
  public eduframeCourseTabs: CourseTab[];
  public eduframeCatalogProduct: ProductWithIncludes;
  public eduframeCourseTabContents: {
    tab: CourseTab;
    content: CourseTabContent;
  }[];
  public eduframeTeacherEnrollment: TeacherWithIncludes[] = [];

  // Eduframe program data
  public eduframeProgram: Program;
  public eduframeProgramEdition: Edition;

  public location_path_arr: string[] = this.location.path().split('/');
  private query: SingleCourseOrClassQuery = {
    query: [
      {
        type: this.location_path_arr[1] === 'namskeid' ? 'course' : 'class',
        course_id: this.location_path_arr[2], // this gives 1688
        planned_course_id: this.location.path().split('/')[4], // this gives 2458
      },
    ],
  };

  private showCost: boolean = true;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private prismic: PrismicService,
    private meta: MetaDataService,
    private educationService: FakeMakerService,
    private general: GeneralService,
    private eduframeCourseTabsService: CourseTabsService,
    private eduframeCatalogProductsService: CatalogProductsService,
    private eduframeCatalogVariantsService: CatalogVariantsService,
    private eduframeCourseService: CoursesService,
    private eduframePlannedCoursesService: PlannedCoursesService,
    private eduframeTeacherEnrollmentsService: TeacherEnrollmentsService,
    private eduframeTeachersService: TeachersService,
    private eduframeProgramsService: ProgramProgramsService,
    private eduframeProgramEditionsService: ProgramEditionsService
  ) {
    this.route.params.subscribe(params => {
      this.eduframePageType = this.location_path_arr[1] as EduframePageType;

      const is_namskeid = this.location_path_arr[1] === 'namskeid';
      const courseId = params['courseId'] as number;
      const plannedCourseId = params['plannedCourseId'] as number;
      const coursename = params['coursename'];

      if (this.eduframePageType === EduframePageType.Course) {
        this.eduframeLoadCourse(courseId, plannedCourseId);
      }

      if (this.eduframePageType === EduframePageType.Program) {
        const programId = params['courseId'] as number;
        const programEditionId = params['plannedCourseId'] as number;
        this.eduframeLoadProgram(programId, programEditionId);
      }

      if (courseId && coursename) {
        this.query.query[0].course_id = courseId.toString();
        this.query.query[0].planned_course_id = plannedCourseId.toString();
        this.query.query[0].type =
          this.location_path_arr[1] === 'namskeid' ? 'course' : 'class';
        this.getCourse(this.query.query[0].type === 'course' ? true : false);
      } else {
        this.router.navigateByUrl('not-found');
      }
    });
  }

  /** Load course data directly from eduframe */
  private async eduframeLoadCourse(courseId: number, plannedCourseId: number) {
    forkJoin([
      this.eduframeCourseService.getCourseById({ id: courseId }),
      this.eduframePlannedCoursesService.getPlannedCoursesByIdAndCourseId({
        id: plannedCourseId,
        courseId: courseId,
      }),
      this.eduframeCatalogVariantsService.getCatalogVariants({
        variantableType: 'planned_course',
        variantableId: plannedCourseId,
      }),
      this.fetchAndSetCourseTabs(), // Fetch and set course tabs
      this.eduframeTeacherEnrollmentsService.getTeacherEnrollmentsByPlannedCourseId(
        { plannedCourseId }
      ),
    ]).subscribe({
      next: ([
        course,
        plannedCourse,
        plannedCourseVariants,
        course_tabs,
        teachers_enrolled,
      ]) => {
        if (
          course.is_published === false ||
          plannedCourse.is_published === false
        ) {
          this.courseNotFound = true;
          return;
        }
        if (course.cost_scheme === 'tbd') {
          this.showCost = false;
        }
        this.eduframeCourse = course;
        this.eduframePlannedCourse = plannedCourse;

        // Sort tabs by position
        this.eduframeCourseTabs = course_tabs.sort(
          (a, b) => a.position - b.position
        );

        // Load course tab contents
        this.eduframeCatalogProductsService
          .getCatalogProducts({
            published: 'published',
            productableType: 'Course',
            search: this.eduframeCourse.name,
            perPage: 500, // FIXME: Wishlist request to eduframe, add filter for productable_id
          })
          .subscribe((products: ProductWithIncludes[]) => {
            // This is _usually_ just one product, but it could be more is there's a matching name.
            this.eduframeCatalogProduct = products.find(
              product => product.productable_id === course.id
            );

            this.eduframeCourseTabContents = this.eduframeCourseTabs.map(
              tab => {
                return {
                  tab,
                  content: this.eduframeCatalogProduct.course_tab_contents.find(
                    content => content.course_tab_id === tab.id
                  ),
                };
              }
            );
          });

        this.eduframeVariant = plannedCourseVariants[0];

        // This is _usually_ just one teacher, but it could be more.
        teachers_enrolled.map(te => {
          this.eduframeTeachersService
            .getTeacherById({ id: te.teacher_id })
            .subscribe((teacher: TeacherWithIncludes) => {
              this.eduframeTeacherEnrollment.push(teacher);
            });
        });
      },
      // FIXME: Handle common errors (404, 500, etc.)
      error: console.error,
    });
  }

  /** Load program data directly from eduframe */
  private async eduframeLoadProgram(
    programId: number,
    programEditionId: number
  ) {
    forkJoin([
      this.eduframeProgramsService.getProgramById({ id: programId }),
      this.eduframeProgramEditionsService.getProgramEditionById({
        id: programEditionId,
      }),
      this.eduframeCatalogVariantsService.getCatalogVariants({
        variantableType: 'program_edition',
        variantableId: programEditionId,
      }),
    ]).subscribe({
      next: ([program, programEdition, programVariants]) => {
        if (program.cost_scheme === 'tbd') {
          this.showCost = false;
        }
        this.eduframeProgram = program;
        this.eduframeProgramEdition = programEdition;
        this.eduframeVariant = programVariants[0];
      },
      error: console.error,
    });
  }

  private fetchAndSetCourseTabs(): Observable<CourseTab[]> {
    return new Observable<CourseTab[]>(observer => {
      this.eduframeCourseTabsService.getCourseTabs().subscribe(
        course_tabs => {
          this.eduframeCourseTabs = course_tabs.sort(
            (a, b) => a.position - b.position
          );
          observer.next(course_tabs);
          observer.complete();
        },
        error => {
          observer.error(error);
        }
      );
    });
  }

  /**
   * @deprecated        Eduframe has replaced FileMaker
   */
  async getCourse(is_namskeid = true): Promise<any> {
    try {
      const data = await this.educationService.getSingleCourseOrClass(
        this.query
      );

      if (data) {
        const courseData = data.response.data[0];

        // Process course data
        this.course = courseData.fieldData;
        if (this.course.Stadsetning) {
          this.course.Stadsetning = this.course.Stadsetning.replace(
            /\u00a0/g,
            ' '
          );
        }
        this.meta.setFmMetaTags(this.course);

        // Process reviews
        if (this.course.Umsagnir) {
          this.umsagnir = this.course.Umsagnir.split('|').map(umsogn => ({
            type: 'generic',
            review: umsogn,
          }));
        }

        // Process additional reviews
        this.gallery = courseData.portalData;
        if (
          this.gallery.Auka_Umsagnir_Vefur &&
          this.gallery.Auka_Umsagnir_Vefur.length > 0
        ) {
          this.gallery.Auka_Umsagnir_Vefur.forEach(extraReview => {
            this.umsagnir.push({
              type: 'extra',
              name: extraReview['Auka_Umsagnir_Vefur::Nafn'] as string,
              review: extraReview['Auka_Umsagnir_Vefur::Umsöng'] as string,
              image_url: extraReview['Auka_Umsagnir_Vefur::Mynd'] as string,
            });
          });
        }

        // Fetch additional data if available
        if (this.course.ID_Yfirflokkur) {
          await Promise.all([
            this.getPrismicContent(this.course.ID_Yfirflokkur),
            this.getEducationGrant(this.course.ID_Yfirflokkur),
          ]);
        }
      }

      // Additional processing for non-course entities
      if (!is_namskeid) {
        const classData =
          await this.educationService.fetchAndPrepereSingleClassData(
            this.query.query[0].course_id as unknown as number,
            this.query.query[0].planned_course_id as unknown as number
          );

        this.eduframeCourse = classData.course;
        this.eduframePlannedCourse = classData;
        this.eduframeCatalogProduct = classData.catalog_product;

        const course_tabs = await this.fetchAndSetCourseTabs().toPromise();
        this.eduframeCourseTabs = course_tabs.sort(
          (a, b) => a.position - b.position
        );
        this.eduframeCourseTabContents = this.eduframeCourseTabs.map(tab => ({
          tab,
          content: this.eduframeCatalogProduct.course_tab_contents.find(
            content => content.course_tab_id === tab.id
          ),
        }));
      }

      this.fmLoaded = this.general.showContent();
    } catch (err) {
      console.error(err);
      if (
        err.error?.messages?.[0]?.message === 'No records match the request'
      ) {
        this.router.navigateByUrl('not-found');
      } else {
        // Handle other errors if necessary
        console.error('An unexpected error occurred: ', err);
      }
    }
  }

  formatUmsagnir(content) {
    if (this.umsagnir) {
      this.umsagnir = content.split('|');
    }
  }

  goBack() {
    this.location.back();
  }

  getPrismicContent(courseType: string) {
    const alertType =
      courseType === 'Y0004' ? 'namsbraut_alert' : 'namskeid_alert';
    this.prismic
      .getDocumentByType(alertType)
      .then((data: PrismicBaseInterface) => {
        if (data) {
          this.prismicAlert = data.results[0].data;
        }
      })
      .then(() => {
        this.prismicLoaded = this.general.showContent();
      });
  }

  /** Generate signup link */
  getSignupUrl(): string {
    const expire_key = environment.eduframe.variant.custom.signup_expire_key;

    if (
      this.eduframeVariant?.custom?.hasOwnProperty(expire_key) &&
      this.eduframeVariant.custom[expire_key]
    ) {
      const date_expiry = new Date(this.eduframeVariant.custom[expire_key]);
      const date_current = new Date();

      // If expiry date is in the past, return empty string, button will not be shown
      if (date_current > date_expiry) {
        console.warn('Signup expired');
        return '';
      }
    }
    if (this.eduframeCourse) {
      return this.eduframeCourse.signup_url;
    }

    if (this.eduframeProgram) {
      return this.eduframeProgram.signup_url;
    }

    return '';
  }

  private getEducationGrant(courseType: string): void {
    const grantType = courseType === 'Y0004' ? 'namsbrautir' : 'namskeid';
    this.prismic.ref.subscribe(ref => {
      if (ref) {
        this.prismic
          .getDocumentByType(grantType)
          .then((data: PrismicBaseInterface) => {
            if (data) {
              this.prismicEducationGrant = data.results[0].data;
            }
          });
      }
    });
  }
}
