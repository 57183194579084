import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { PrismicMasterRef } from '@endurmenntun/_models/prismic/masterref.interface';

@Injectable({
  providedIn: 'root',
})
export class PrismicService {
  private env = environment;
  private docRef = '/documents/search?ref=';
  private url = this.env.prismic.endpoint + this.docRef;
  public ref = new BehaviorSubject('');

  constructor(
    private http: HttpClient,
  ) { }

  getMasterRef() {
    return this.http.get<PrismicMasterRef>(this.env.prismic.endpoint);
  }

  assignRef() {
    if (!this.ref.getValue()) {
      this.getMasterRef().subscribe(ref => {
        this.ref.next(ref.refs[0].ref);
      });
    }
  }

  getDocumentByTag(tag): Promise<unknown> {
    const promise = new Promise((resolve, reject) => {
      const apiUrl =
        this.env.prismic.endpoint +
        this.docRef +
        this.ref.getValue() +
        '&q=[[at(document.tags,' +
        '["' +
        tag +
        '"]' +
        ')]]';
      this.http
        .get(apiUrl)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  getDocumentByType(type, orderings?: string, descending?: boolean) {
    const url = this.url;
    const apiUrl =
      url + this.ref.getValue() + `&q=[[at(document.type,"${type}")]]`;
    let order;
    if (orderings) {
      order = `&orderings=[my.${type}.${orderings} ${
        descending ? 'desc' : ''
      }]`;
    }
    const promise = new Promise((resolve, reject) => {
      this.http
        .get(apiUrl + order)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  getAllEmployees(orderings?: string, descending?: boolean): Promise<any> {
    const url = this.url;
    const apiUrl =
      url + this.ref.getValue() + `&q=[[at(document.type, "employee")]]`;
    const pageSize = '&pageSize=100';
    let order: string;
    if (orderings) {
      order = `&orderings=[my.employee.${orderings} ${
        descending ? 'desc' : ''
      }]`;
    }
    const promise = new Promise((resolve, reject) => {
      this.http
        .get(apiUrl + pageSize + order)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  getArticlesByType(type: string, pageSize: number = 60): Promise<any> {
    const url = this.url;
    const query = `&q=[[at(document.type,"${type}")]]`;
    const order = `&orderings=[my.${type}.article_date desc]`;
    const size = `&pageSize=${pageSize}`; // Add this line to specify the desired page size

    const promise = new Promise((resolve, reject) => {
      this.http
        .get(url + this.ref.getValue() + query + order + size) // Add the size parameter to the request URL
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  getDocumentByUid(pageUID) {
    const promise = new Promise((resolve, reject) => {
      const apiUrl =
        this.env.prismic.endpoint +
        this.docRef +
        this.ref.getValue() +
        '&q=[[at(my.page.uid,' +
        '"' +
        pageUID +
        '"' +
        ')]]';
      return this.http
        .get(apiUrl)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  getArticleByUid(articleUID, location) {
    const url = this.env.prismic.endpoint + '/documents/search';
    const articleType = location.includes('frettir') ? 'frett' : 'greinar';
    const query = `[[at(my.${articleType}.uid,"${articleUID}")]]`;
    const promise = new Promise((resolve, reject) => {
      this.http
        .get(url, { params: { ref: this.ref.getValue(), q: query } })
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  getAlertByUid(alertUID) {
    const promise = new Promise((resolve, reject) => {
      this.http
        .get(
          this.env.prismic.endpoint +
            this.docRef +
            this.ref.getValue() +
            '&q=[[at(my.alert_page.uid,' +
            '"' +
            alertUID +
            '"' +
            ')]]'
        )
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  public getCustomPageByUid(pageUID) {
    const promise = new Promise((resolve, reject) => {
      const apiUrl =
        this.env.prismic.endpoint +
        this.docRef +
        this.ref.getValue() +
        '&q=[[at(my.custom_content_page,' +
        '"' +
        pageUID +
        '"' +
        ')]]';
      this.http
        .get(apiUrl)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  getCustomPageByUID(pageUID): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      const apiUrl =
        this.env.prismic.endpoint +
        this.docRef +
        this.ref.getValue() +
        `&q=[[at(my.custom_content_page.uid,"${pageUID}")]]`;
      this.http
        .get(apiUrl)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  getFormByUid(formUID) {
    const promise = new Promise((resolve, reject) => {
      const apiUrl =
        this.env.prismic.endpoint +
        this.docRef +
        this.ref.getValue() +
        '&q=[[at(my.form.uid,' +
        '"' +
        formUID +
        '"' +
        ')]]';
      this.http
        .get(apiUrl)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  getDocumentByID(ID) {
    const promise = new Promise((resolve, reject) => {
      const apiUrl =
        this.env.prismic.endpoint +
        this.docRef +
        this.ref.getValue() +
        '&q=[[at(document.id,' +
        '"' +
        ID +
        '"' +
        ')]]';
      this.http
        .get(apiUrl)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  searchPrismic() {
    const promise = new Promise((resolve, reject) => {
      const apiUrl =
        this.env.prismic.endpoint +
        this.docRef +
        this.ref.getValue() +
        '&q=[[fulltext(document, "endurmenntun")]]';
      this.http
        .get(apiUrl)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }
}
